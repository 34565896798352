const $ = require('jquery')

function initHeaderHeadroom () {
  // const $headeroomScroller = document.querySelector('.site-wrapper')
  const $elementHeader = $('.js-site-header')
  // $elementHeader.css('position', 'fixed')
  $elementHeader.headroom({
    offset: 50,
    tolerance: {
      up: 20,
      down: 5,
    },
    // scroller: $headeroomScroller,
  })

}
// if (Modernizr.mq('(min-width: 64.99em)')) {
  initHeaderHeadroom()
// }
