const $ = require('jquery')
require('./../vendor/jsCaptcha.js')

function submitContactForm () {
  const $form = $('body').find('.js-contact-form')
  const formInputs = $form.find('input, textarea')
  const $formMessages = $('body').find('.js-msg')
  const $formFields = $form.find('.js-field-engage')
  $formMessages.hide()
  // console.log(formInputs)
  const dataAction = $form.data('action')
  const dataMethod = $form.data('method')
  const dataSubject = $form.data('subject')
  const msgSuccess = $form.find('.js-success')
  const msgError = $form.find('.js-error')

  const $name = $form.find('#name')
  const $email = $form.find('#email')
  const $phone = $form.find('#phone')
  const $street = $form.find('#street')
  const $city = $form.find('#city')
  const $state = $form.find('#state')
  const $postcode = $form.find('#postcode')
  const $country = $form.find('#country')

  const baseURL = $form.data('base-url')

  // validation
  $form.textCaptcha({
    type: 'math',
      method: 'add',
      length: 3
  });

  $.validator.addMethod("captchaCheck",
    function() {
      return $form.textCaptchaCheck();
    },
    "Captcha value does not match");

  $form.validate({
    ignore: '.ignore',
    debug: true,
    errorElement: 'em',
    errorPlacement: function (error, element) {
      error.appendTo(element.closest('.form-field'))
    },
    success: function (label) {
      return true
    },
    highlight: function (element) {
      $(element).closest('.form-field').addClass('field-error')
    },
    unhighlight: function (element) {
      $(element).closest('.form-field').removeClass('field-error')
    },
    rules: {
      captcha_user: {
        required: true,
        captchaCheck: true
      }
    },
    submitHandler: function (form) {

        if ($form.valid()) {

          $.post(dataAction, {
            name: $name.val(),
            email: $email.val(),
            phone: $phone.val(),
            street: $street.val(),
            city: $city.val(),
            state: $state.val(),
            postcode: $postcode.val(),
            country: $country.val(),
          }).done(function (data) {

            msgSuccess.slideDown('fast')

            $form.trigger('reset')
            $formFields.each(function () {
              $(this).removeClass('is--engaged')
            })
            if (Modernizr.mq('(min-width: 64.99em)')) {
              $.ajax({
                  url: `${baseURL}FnObrochure.pdf`,
                  method: 'GET',
                  xhrFields: {
                      responseType: 'blob'
                  },
                  success: function (data) {
                      var a = document.createElement('a');
                      var url = window.URL.createObjectURL(data);
                      a.href = url;
                      a.download = 'FnObrochure.pdf';
                      a.click();
                      window.URL.revokeObjectURL(url);
                  }
              });

            } else {
              window.open('/FnObrochure.pdf', '_blank');
            }

          }).fail(function (data) {
            msgError.slideDown('fast')
            console.log(data)

          })
        }

    }
  })


}

submitContactForm()
