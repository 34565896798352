const $ = require('jquery')
window.jQuery = global.jQuery = global.$ = $

require('./component/modernizr')

require('jquery-validation')
global.Headroom = require('headroom.js')
require('headroom.js/dist/jQuery.headroom.js')
require('jquery.localscroll')
require('jquery.scrollto')
require('slick-carousel')
// global.PerfectScrollbar = require('perfect-scrollbar')
global.fancybox = require('@fancyapps/fancybox')
global.Rellax = require('rellax')
// global.BadgerAccordion = require('badger-accordion')
require('./component/fancybox-options')

$(document).ready(function () {
  require('./component/_main')
})
