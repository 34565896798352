require('browsernizr/lib/html5shiv')
require('browsernizr/lib/domPrefixes')
require('browsernizr/lib/mq')
require('browsernizr/lib/testPropsAll')
require('browsernizr/lib/testDOMProps')
// require('browsernizr/test/video')
// require('browsernizr/test/video/autoplay')
// require('browsernizr/test/eventlistener')
require('browsernizr/test/css/flexbox')
require('browsernizr/test/css/animations')
// require('browsernizr/test/css/scrollbars')
// require('browsernizr/test/css/backgroundblendmode')
require('browsernizr/test/forms/placeholder')
require('browsernizr/test/touchevents')
require('browsernizr/test/svg')

// make sure to do this _after_ importing the tests
require('browsernizr')
global.Modernizr = require('browsernizr')

// if (Modernizr.eventlistener) {
//   console.log('eventlistener supported')
// } else {
//   console.log('eventlistener not supported')
// }

// if (Modernizr.videoautoplay) {
//   console.log('video not supported')
// } else {
//   console.log('video supported')
// }

// if (Modernizr.cssscrollbar) {
//   console.log('css scrollbar supported')
// } else {
//   console.log('css scrollbar not supported')
// }
