const $ = require('jquery')

const $menuTarget = $('body')
const $menuTrigger = $('.js-menu-toggle')
$menuTrigger.on('click', function () {
  $menuTarget.toggleClass('nav--is-active')
})

$(document).on('click', function (e) {
  if (!$(e.target).closest('.js-menu-toggle').length) {
    $menuTarget.removeClass('nav--is-active')
  }
})

