const $ = require('jquery')

function initTextFields () {
  const $inputContainer = $('.js-field-engage')
  const $inputs = $('.js-field-engage input, .js-field-engage textarea')
  const $inputDivs = ($('body').find('.js-field-engage div.form-control'))

  $inputDivs.each(function () {
    const $this = $(this)
    if ($(this).text().trim().length) {
      $this
        .prop('placeholder', '')
        .closest('.js-field-engage').addClass('is--engaged')
    }
  })

  $inputs.each(function () {
    const $this = $(this)
    $this.data('originalPlaceholder', $this.prop('placeholder'))
    if ($this.val()) {
      $this
        .prop('placeholder', '')
        .closest('.js-field-engage').addClass('is--engaged')
    }
  })

  $inputs.on('focusin', function () {
    const $this = $(this)
    $this.prop('placeholder', '').closest('.js-field-engage').addClass('is--engaged')
  }).on('focusout change select2-close', function () {
    const $this = $(this)
    if ($this.val()) {
      $this
        .prop('placeholder', $this.data('originalPlaceholder'))
        .closest('.js-field-engage').addClass('is--engaged')
    } else {
      $this
        .prop('placeholder', $this.data('originalPlaceholder'))
        .closest('.js-field-engage').removeClass('is--engaged')
    }
  })
}

function initSelects () {
  const $selects = $('.js-field-engage select')
  $selects.each(function () {
    const $this = $(this)
    const $container = $this.closest('.js-field-engage')
    // if ($this.val() !== 'placeholder') {
    if ($this.val() !== '') {
      $container.addClass('is--engaged')
    } else {
      $container.removeClass('is--engaged')
    }
  })
  $selects.on('change', function () {
    const $this = $(this)
    const $container = $this.closest('.js-field-engage')
    if ($this.val() !== '') {
    // if ($this.val() !== 'placeholder') {
      $container.addClass('is--engaged')
    } else {
      $container.removeClass('is--engaged')
    }
  })
}

initSelects()
initTextFields()
