const $ = require('jquery')

const $accordions = $('.js-badger-accordion')
const $accordionHeaders = $accordions.find('.badger-accordion__header')
const $accordionPanels = $accordions.find('.js-badger-accordion-panel')

const $villaBtn = $('.js-open-villa')

$accordionPanels.hide()

$accordionHeaders.each(function () {
  const $this = $(this)
  $this.on('click', function () {
    const $this = $(this)
    const $panel = $this.next('.js-badger-accordion-panel')

    const $triggers = $accordionHeaders.not($this)
    const $panels = $accordionPanels.not($panel)

    $triggers.removeClass('expanded')
    $panels.slideUp(300)
    $panel.slideToggle(300).toggleClass('expanded')
    $this.toggleClass('expanded')

    setTimeout(function () {
      $.scrollTo($this, 500, {offset: -120})
    }, 400)
  })
})

$villaBtn.each(function () {
  const $this = $(this)
  $this.on('click', function (e) {
    e.preventDefault()
    const $this = $(this)
    let villaIndex = $this.data('villa')
    const $header = $(`dt.badger-accordion__header:eq(${villaIndex})`)
    const $headers = $accordionHeaders.not($header)

    const $panel = $header.next('.js-badger-accordion-panel')
    const $panels = $accordionPanels.not($panel)
    $panels.slideUp(300)
    $headers.removeClass('expanded')
    $panel.slideToggle(300).toggleClass('expanded')

    $header.toggleClass('expanded')

    setTimeout(function () {
      if ($header.hasClass('expanded')) {
        $.scrollTo($header, 500, {offset: 0})
      }
    }, 400)
  })
})
