const $ = require('jquery')

function rellaxScroll () {
  // const scrollFlag = $('body.rellax-init')
  // if (scrollFlag.length > 0) {
    var rellax = new Rellax('.rellax', {
      // wrapper: document.querySelector('body'),
      // speed: -2,
      center: true,
      // round: true,
      // vertical: true,
      // horizontal: false,
    })
  }
   // rellax.destroy();
   // rellax.refresh();
// }
 if (Modernizr.mq('(min-width: 64.99em)')) {
  rellaxScroll()
}

