$('.js-open-brochure-form').on('click', function (){
  $.fancybox.open({
    src  : '#brochure-form',
    type : 'inline',
    baseClass: 'brochure',
    clickSlide : 'false',
    clickOutside : 'false',
    touch : false
  });
})

$.fancybox.defaults.btnTpl.arrowLeft = `<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;">
<svg width="19px" height="34px" viewBox="0 0 19 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="0%" y1="0%" x2="97.9723429%" y2="0%" id="linearGradient-arrow-left">
            <stop stop-color="#EFAC99" offset="0%"></stop>
            <stop stop-color="#AD5740" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g transform="translate(-49.000000, -480.000000)" stroke="url(#linearGradient-arrow-left)" stroke-width="2">
            <g transform="translate(58.500000, 497.000000) rotate(-180.000000) translate(-58.500000, -497.000000) translate(50.000000, 481.000000)">
                <path d="M0.995907899,31.0040921 L16,16" id="Line-2"></path>
                <path d="M2.30926389e-14,16 L15.5446073,0.455392675" id="Line-2" transform="translate(8.000000, 8.500000) scale(-1, 1) translate(-8.000000, -8.500000) "></path>
            </g>
        </g>
    </g>
</svg></a>`
$.fancybox.defaults.btnTpl.arrowRight = `<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;">
<svg width="19px" height="34px" viewBox="0 0 19 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="0%" y1="0%" x2="97.9723429%" y2="0%" id="linearGradient-arrow-right">
            <stop stop-color="#EFAC99" offset="0%"></stop>
            <stop stop-color="#AD5740" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g transform="translate(-1359.000000, -480.000000)" stroke="url(#linearGradient-arrow-right)" stroke-width="2">
            <g transform="translate(1360.000000, 481.000000)">
                <path d="M0.995907899,31.0040921 L16,16" id="Line-2"></path>
                <path d="M2.30926389e-14,16 L15.5446073,0.455392675" transform="translate(8.000000, 8.500000) scale(-1, 1) translate(-8.000000, -8.500000) "></path>
            </g>
        </g>
    </g>
</svg></a>`

$.fancybox.defaults.btnTpl.close = `<button data-fancybox-close class="fancybox-close-small" title="{{CLOSE}}">
    <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="0%" x2="97.9723429%" y2="0%" id="linearGradient-1">
                <stop stop-color="#EFAC99" offset="0%"></stop>
                <stop stop-color="#AD5740" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
            <g id="Pop-Up" transform="translate(-1359.000000, -46.000000)" stroke="url(#linearGradient-1)" stroke-width="2">
                <g id="Group" transform="translate(1360.000000, 47.000000)">
                    <path d="M0.995907899,31.0040921 L31.5446073,0.455392675" id="Line-2"></path>
                    <path d="M0.995907899,31.0040921 L31.5446073,0.455392675" id="Line-2" transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "></path>
                </g>
            </g>
        </g>
    </svg>
</button>`
$.fancybox.defaults.btnTpl.smallBtn = `<button data-fancybox-close class="fancybox-close-small" title="{{CLOSE}}">
    <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="0%" x2="97.9723429%" y2="0%" id="linearGradient-1">
                <stop stop-color="#EFAC99" offset="0%"></stop>
                <stop stop-color="#AD5740" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
            <g id="Pop-Up" transform="translate(-1359.000000, -46.000000)" stroke="url(#linearGradient-1)" stroke-width="2">
                <g id="Group" transform="translate(1360.000000, 47.000000)">
                    <path d="M0.995907899,31.0040921 L31.5446073,0.455392675" id="Line-2"></path>
                    <path d="M0.995907899,31.0040921 L31.5446073,0.455392675" id="Line-2" transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "></path>
                </g>
            </g>
        </g>
    </svg>
</button>`

$.fancybox.defaults.btnTpl.zoom = `<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}">
            <svg width="33px" height="34px" viewBox="0 0 33 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                  <linearGradient x1="8.61570776%" y1="78.9251685%" x2="82.716672%" y2="3.92198073%" id="search-gradient">
                      <stop stop-color="#EFAC99" offset="0%"></stop>
                      <stop stop-color="#AD5740" offset="100%"></stop>
                  </linearGradient>
              </defs>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1301.000000, -47.000000)" fill="url(#search-gradient)">
                      <path d="M1333.8208,79.9159095 L1324.20621,69.9945954 C1326.65859,67.5527837 1328.17838,64.189776 1328.17838,60.481721 C1328.17838,53.0463525 1322.07979,47 1314.58919,47 C1307.09455,47 1301,53.0463525 1301,60.481721 C1301,67.9138798 1307.09455,73.963442 1314.58919,73.963442 C1317.8779,73.963442 1320.89566,72.7942933 1323.24936,70.8604234 L1332.88579,80.8058106 C1333.01196,80.93741 1333.18263,81 1333.35329,81 C1333.51425,81 1333.67521,80.9406198 1333.80219,80.8218593 C1334.05778,80.5771164 1334.06749,80.1694791 1333.8208,79.9159095 Z M1314,72 C1307.38141,72 1302,66.6154352 1302,60 C1302,53.3814067 1307.38141,48 1314,48 C1320.61544,48 1326,53.3814067 1326,60 C1326,66.6154352 1320.61544,72 1314,72 Z"></path>
                  </g>
              </g>
          </svg>
          </button>`

